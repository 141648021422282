<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-input
						v-model="searchForm.name"
						placeholder="名称"
						clearable
				>
				</el-input>
			</el-form-item>

			<el-form-item>
				<el-button @click="getDeptList">搜索</el-button>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="dialogVisible = true">新增</el-button>
			</el-form-item>
			<el-form-item>
				<el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
					<el-button type="danger" slot="reference" :disabled="delBtlStatu">批量删除</el-button>
				</el-popconfirm>
			</el-form-item>
			<div>
 
	        <el-form-item>
				<el-button @click="sendInfo1">flutter1</el-button>
			</el-form-item>
			<el-form-item>
				<el-button @click="sendInfo2">flutter2</el-button>
			</el-form-item>
			<el-form-item>
				<el-button @click="sendInfo3">flutter3</el-button>
			</el-form-item>
			<el-form-item>
				<el-button @click="sendInfo4">flutter4</el-button>
			</el-form-item>

			<el-form-item>
				<el-button @click="sendInfo5">flutter_Scan</el-button>
			</el-form-item>
		 
			<input   type="text"  v-model="qrcode" class="fuse-qrcode-value"   style="width: 300px;" /> 
			<el-form-item>
				<el-button @click="callAPPScannerResponse('123','456')">flutter_444444444</el-button>
				<!-- <el-button :plain="true" @click="open">打开消息提示</el-button> -->
			</el-form-item>
  </div>
		</el-form>

		<el-table
				ref="multipleTable"
				:data="tableData"
				tooltip-effect="dark"
				style="width: 100%"
				border
				stripe
				@selection-change="handleSelectionChange">

			<el-table-column
					type="selection"
					width="55">
			</el-table-column>

			<el-table-column
					prop="id"
					label="序号"
					width="120">
			</el-table-column>
			<el-table-column
					prop="lineid"
					label="线体编码"
					show-overflow-tooltip>
			</el-table-column>
			<el-table-column
					prop="linename"
					label="线体名称"
					show-overflow-tooltip>
			</el-table-column>
	<el-table-column
					prop="created"
					label="创建时间"
					show-overflow-tooltip>
			</el-table-column>
	<el-table-column
					prop="createuser"
					label="创建人"
					show-overflow-tooltip>
			</el-table-column>


			<el-table-column
					prop="statu"
					label="状态">
				<template slot-scope="scope">
					<el-tag size="small" v-if="scope.row.statu === 1" type="success">正常</el-tag>
					<el-tag size="small" v-else-if="scope.row.statu === 0" type="danger">禁用</el-tag>
				</template>

			</el-table-column>
			<el-table-column
					prop="icon"
					label="操作">

				<template slot-scope="scope">
					<!-- <el-button type="text" @click="permHandle(scope.row.id)">分配权限</el-button> -->
					<!-- <el-divider direction="vertical"></el-divider> -->

					<el-button type="text" @click="editHandle(scope.row.id)">编辑</el-button>
					<el-divider direction="vertical"></el-divider>

					<template>
						<el-popconfirm title="这是一段内容确定删除吗？" @confirm="delHandle(scope.row.id)">
							<el-button type="text" slot="reference">删除</el-button>
						</el-popconfirm>
					</template>

				</template>
			</el-table-column>

		</el-table>


		<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				layout="total, sizes, prev, pager, next, jumper"
				:page-sizes="[10, 20, 50, 100]"
				:current-page="current"
				:page-size="size"
				:total="total">
		</el-pagination>


		<!--新增对话框-->
		<el-dialog
				title="提示"
				:visible.sync="dialogVisible"
				width="600px"
				:before-close="handleClose">

			<el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">

				

				<el-form-item label="线体编码" prop="lineid" label-width="100px">
					<el-input v-model="editForm.lineid" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="线体名称" prop="linename" label-width="100px">
					<el-input v-model="editForm.linename" autocomplete="off"></el-input>
				</el-form-item>
				<!-- <el-form-item label="描述" prop="remark" label-width="100px">
					<el-input v-model="editForm.remark" autocomplete="off"></el-input>
				</el-form-item> -->


				<el-form-item label="状态" prop="statu" label-width="100px">
					<el-radio-group v-model="editForm.statu">
						<el-radio :label=0>禁用</el-radio>
						<el-radio :label=1>正常</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('editForm')">立即创建</el-button>
					<el-button @click="resetForm('editForm')">重置</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>

 

	</div>
</template>

<script>
  
	export default {
		name: "Role",
		data() {
			return {
				searchForm: {},
				delBtlStatu: true,
				infoFromFlutter: '',
				total: 0,
				size: 10,
				current: 1,
				qrcode:'',
				dialogVisible: false,
				editForm: {

				},

				tableData: [],

				editFormRules: {
					linename: [
						{required: true, message: '请输入线体名称', trigger: 'blur'}
					],
					lineid: [
						{required: true, message: '请输入线体编码', trigger: 'blur'}
					],
					statu: [
						{required: true, message: '请选择状态', trigger: 'blur'}
					]
				},

				multipleSelection: [],

				permDialogVisible: false,
				permForm: {},
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				permTreeData: []
			}
		},
		created() {
			this.getDeptList()

			this.$axios.get('/bas/line/list').then(res => {
				this.permTreeData = res.data.data
			})
		},
 
mounted: function() {
  
  window["callAPPScannerResponse"] = ( action, message)   => {
	this.callAPPScannerResponse( action, message)  ;
  };
 
}, 



		methods: {
			
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				console.log("勾选")
				console.log(val)
				this.multipleSelection = val;

				this.delBtlStatu = val.length == 0
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.size = val
				this.getDeptList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.current = val
				this.getDeptList()
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisible = false
				this.editForm = {}
			},
			handleClose() {
				this.resetForm('editForm')
			},

			getDeptList() {
				this.$axios.get("/bas/line/list", {
					params: {
						name: this.searchForm.name,
						current: this.current,
						size: this.size
					}
				}).then(res => {
					this.tableData = res.data.data.records
					this.size = res.data.data.size
					this.current = res.data.data.current
					this.total = res.data.data.total
				})
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/bas/line/' + (this.editForm.id?'update' : 'save'), this.editForm)
							.then(res => {

								this.$message({
									showClose: true,
									message: '恭喜你，操作成功',
									type: 'success',
									onClose:() => {
										this.getDeptList()
									}
								});

								this.dialogVisible = false
								this.resetForm(formName)
							})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			editHandle(id) {
				this.$axios.get('/bas/line/info/' + id).then(res => {
					this.editForm = res.data.data

					this.dialogVisible = true
				})
			},
			delHandle(id) {

				var ids = []

				if (id) {
					ids.push(id)
				} else {
					this.multipleSelection.forEach(row => {
						ids.push(row.id)
					})
				}

				console.log(ids)

				this.$axios.post("/bas/line/delete", ids).then(res => {
					this.$message({
						showClose: true,
						message: '恭喜你，操作成功',
						type: 'success',
						onClose:() => {
							this.getDeptList()
						}
					});
				})
			},
			permHandle(id) {
				this.permDialogVisible = true

				this.$axios.get("/bas/line/info/" + id).then(res => {

					this.$refs.permTree.setCheckedKeys(res.data.data.menuIds)
					this.permForm = res.data.data
				})
			},

			submitPermFormHandle(formName) {
				var menuIds = this.$refs.permTree.getCheckedKeys()

				console.log(menuIds)

				this.$axios.post('/bas/line/perm/' + this.permForm.id, menuIds).then(res => {
					this.$message({
						showClose: true,
						message: '恭喜你，操作成功',
						type: 'success',
						onClose:() => {
							this.getDeptList()
						}
					});
					this.permDialogVisible = false
					this.resetForm(formName)
				})
			},
			
	 sendInfo1() {
     // window.parent.postMessage({ infoFromVue: 'Hello, Flutter!' }, '*');
	  window.flutter_inappwebview.callHandler('AppAudio', 'd.wav');
    },
	sendInfo2() {
     // window.parent.postMessage({ infoFromVue: 'Hello, Flutter!' }, '*');
	  window.flutter_inappwebview.callHandler('AppAudio', 'e.wav');
    },
	sendInfo3() {
     // window.parent.postMessage({ infoFromVue: 'Hello, Flutter!' }, '*');
	//   window.flutter_inappwebview.callHandler('AppAudio', 'e.wav');
	  window.flutter_inappwebview.callHandler('AppVibrate', '調用震動功能');
    },	
	sendInfo4() {
     // window.parent.postMessage({ infoFromVue: 'Hello, Flutter!' }, '*');
	 window.flutter_inappwebview.callHandler('AppMessage', 'test');
    },
	sendInfo5() {
     // window.parent.postMessage({ infoFromVue: 'Hello, Flutter!' }, '*');
	 window.flutter_inappwebview.callHandler('AppScanner', '調用掃碼功能');
	 $('.fuse-qrcode-text').val('調用掃碼功能');
    }, 
	open() {
        this.$message('这是一条消息提示');
      },
	callAPPScannerResponse( action, message)  
	{ try 
			{
				this.$message('这是一条消息提示44444444444444-'+message);
				// $('.fuse-qrcode-value').find('input:first').val(message).trigger('change');
				// $('.fuse-qrcode-value').val(message).trigger('change');
			 this.qrcode =message;
			} 
			catch (error) 
			{ 

			}
			try 
			{
				$('#btnAppScanCallBack').click();
			} 
			catch (error) 
			{

			 }
    },

 


		}










	}
	
</script>

<style scoped>

	.el-pagination {
		float: right;
		margin-top: 22px;
	}

</style>